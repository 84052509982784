import { TFunction } from 'i18next';
import * as React from 'react';

export function SerbiaSSOTab({ t }: { t: TFunction }) {
    return (
        <>
            <div className="login__serbia-sso-message">
                <p>
                    <strong>{t('views.login.serbiaSSO.title')}</strong>
                </p>
                <p className="mt-3">{t('views.login.serbiaSSO.description.redirection')}</p>
            </div>
        </>
    );
}
