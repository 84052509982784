import * as React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';

import { Button, ButtonType } from '../../../components/Buttons/Button';
import { ICONS } from '../../../components/Icon/Icon';
import { createDataId } from '../../../common/utils/dataId';
import withSuspense from '../../../common/hocs/withSuspense';
import ContentBlockHeading, { ContentBlockHeadingType } from '../../../components/ContentBlock/ContentBlockHeading';
import { ContentBlockHeader } from '../../../components/ContentBlock/ContentBlockHeader';
import { ContentBlockBody } from '../../../components/ContentBlock/ContentBlockBody';
import { ContentBlockFooter } from '../../../components/ContentBlock/ContentBlockFooter';
import { redirectToLogin, sendPasswordResetLink } from '../loginHelper';

export interface Props {
    email: string;
}

export type EmailSentProps = Props & WithTranslation;

const dataId = 'emailSent';

export const EmailSent = (props: EmailSentProps) => {
    const { t } = props;

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [timer, setTimer] = React.useState(60);

    React.useEffect(() => {
        const interval: NodeJS.Timeout = setInterval(() => {
            timer > 0 && setTimer((time) => time - 1);
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const resendLink = async () => {
        const sendResult = await sendPasswordResetLink(props.email);
        if (sendResult) {
            setTimer(60);
        }
        setIsLoading(false);
    };

    return (
        <>
            <ContentBlockHeader className="login__block-header">
                <Button className="login__block--back-btn" onClick={redirectToLogin} dataId={createDataId(dataId, 'backBtn')} buttonType={ButtonType.ICON_TEXT} icon={ICONS.ARROW_LEFT_24}>
                    {t('views.login.forgotPassword.backToLogin')}
                </Button>
                <div className="login__block-header-container">
                    <ContentBlockHeading type={ContentBlockHeadingType.BOLD} dataId={createDataId(dataId, 'headingBlockTitle')}>
                        {t('views.login.forgotPassword.emailSent')}
                    </ContentBlockHeading>
                </div>
            </ContentBlockHeader>
            <ContentBlockBody className="login__block-body forgot-password" dataId={createDataId(dataId, 'blockBody')}>
                <h4>{t('views.login.forgotPassword.emailSent.checkMail')}</h4>
                <p>
                    {
                        <Trans i18nKey={'views.login.forgotPassword.emailSent.checkMailBody'} values={{ email: props.email }}>
                            <b>{props.email}</b>
                        </Trans>
                    }
                </p>
                <p>{t('views.login.forgotPassword.emailSent.checkMailFooter')}</p>
            </ContentBlockBody>
            <ContentBlockFooter className="login__block-footer" noSeparator={false}>
                <Button
                    disabled={timer > 0}
                    onClick={() => {
                        setIsLoading(true);
                        resendLink();
                    }}
                    dataId={createDataId(dataId, 'button.continue')}
                    loading={isLoading}
                    className="login__button"
                    id="loginButton"
                >
                    {timer > 0 ? t('views.login.forgotPassword.button.newRequest', { sec: timer }) : t('views.login.forgotPassword.button.resendLink')}
                </Button>
            </ContentBlockFooter>
        </>
    );
};

export default withSuspense(withTranslation()(EmailSent));
