import React, { useState } from 'react';
import classNames from 'classnames';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import withSuspense from '../../../../common/hocs/withSuspense';
import { ICONS } from '../../../../components/Icon/Icon';
import { ContentBlockBody } from '../../../../components/ContentBlock/ContentBlockBody';
import { ContentBlock } from '../../../../components/ContentBlock/ContentBlock';
import { ContentBlockHeader } from '../../../../components/ContentBlock/ContentBlockHeader';
import { ContentBlockFooter } from '../../../../components/ContentBlock/ContentBlockFooter';
import ContentBlockHeading, { ContentBlockHeadingType } from '../../../../components/ContentBlock/ContentBlockHeading';
import { createDataId } from '../../../../common/utils/dataId';
import { LoginMethods, LoginUserDTO } from '../../../../services/controllers/LoginService';
import { LoginFields } from '../../LoginView';
import { Button, ButtonType } from '../../../../components/Buttons/Button';

import './UsersSelector.scss';

export interface UsersSelectorProps extends WithTranslation {
    isLoading: boolean;
    users: LoginUserDTO[];
    values: LoginFields;
    personalCode: string;
    loginMethod?: LoginMethods;
    backToLogin: () => void;
    handleLogin: (user: LoginUserDTO, values: LoginFields, loginMethod: LoginMethods) => void;
}

const dataId = 'loginUsersSelector';

const UsersSelector = (props: UsersSelectorProps) => {
    const { isLoading, users, values, personalCode, loginMethod, backToLogin, handleLogin, t } = props;
    const [selectedUserIndex, setSelectedUserIndex] = useState(null);

    return (
        <section className="user-selector__container">
            <ContentBlock className="user-selector__block">
                <ContentBlockHeader className="login__block-header">
                    <Button className="login__block--back-btn" onClick={backToLogin} dataId={createDataId(dataId, 'backBtn')} buttonType={ButtonType.ICON_TEXT} icon={ICONS.ARROW_LEFT_24}>
                        {t('views.login.forgotPassword.backToLogin')}
                    </Button>
                    <div className="login__block-header-container">
                        <ContentBlockHeading type={ContentBlockHeadingType.BOLD} dataId={createDataId(dataId, 'headingBlockTitle')}>
                            {t('views.login.userSelector.selectUserTitle')}
                        </ContentBlockHeading>
                    </div>
                </ContentBlockHeader>
                <ContentBlockBody className="login__block-body forgot-password" dataId={createDataId(dataId, 'blockBody')}>
                    <div className="user-selector__body">
                        <div className="user-selector__body-title">
                            <Trans i18nKey={'views.login.userSelector.selectUserBody'} values={{ personalCode: personalCode || '-' }}>
                                <b>{personalCode || '-'}</b>
                            </Trans>
                        </div>
                        <div className="user-selector__users">
                            {users.map((user, i) => (
                                <div className={classNames('user-selector__user', { 'user--active': selectedUserIndex === i })} key={i} onClick={() => setSelectedUserIndex(i)}>
                                    <div className="user-selector__avatar" data-id={createDataId(dataId, 'avatar')}>
                                        {(user.FirstName?.charAt(0) + user.LastName?.charAt(0)).toUpperCase()}
                                    </div>
                                    <div className="user-selector__info">
                                        <p>{`${user.FirstName} ${user.LastName}`}</p>
                                        <p>{user.Email || '-'}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </ContentBlockBody>
                <ContentBlockFooter className="user-selector__block__footer" noSeparator={false}>
                    <Button
                        loading={isLoading}
                        onClick={() => handleLogin(users[selectedUserIndex], values, loginMethod)}
                        disabled={selectedUserIndex === null}
                        dataId={createDataId(dataId, 'button.backToLoginPage')}
                        className="login__button"
                        id="loginButton"
                    >
                        {t('views.global.continue')}
                    </Button>
                </ContentBlockFooter>
            </ContentBlock>
        </section>
    );
};

export default withSuspense(withTranslation()(UsersSelector));
