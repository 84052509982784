const isShowPdfPreviewAutomaticallyKey = 'ls.invoiceDocuments.showPdfPreviewAutomatically';

export const getIsShowPdfPreviewAutomatically = (): boolean => {
    const value = localStorage.getItem(isShowPdfPreviewAutomaticallyKey);
    if (value === null) {
        return true;
    }
    return !!JSON.parse(value);
};

export const setIsShowPdfPreviewAutomatically = (value: string) => {
    localStorage.setItem(isShowPdfPreviewAutomaticallyKey, value);
};
