import { ReactHTML } from 'react';
import { TypographyProps } from './TypographyTypes';
import classNames from 'classnames';

export const rootClassName = 'ui-typography';

const isHeading = (variant: TypographyProps['variant']) => {
    const headingVariants: ('h1' | 'h2' | 'h3')[] = ['h1', 'h2', 'h3'];
    return headingVariants.some((el) => el === variant);
};

type Params = Pick<TypographyProps, 'variant' | 'hideOverflow'>;

export const useClasses = (params: Params) => {
    const { variant, hideOverflow } = params;
    return {
        root: classNames(rootClassName, { [`${rootClassName}--hide-overflow`]: hideOverflow }),
        variant: isHeading(variant) ? `${rootClassName}--variant-header-${variant}` : `${rootClassName}--variant-${variant}`,
    };
};

export const getHtmlElement = (variant: TypographyProps['variant'], element: keyof ReactHTML): keyof ReactHTML => {
    if (element) {
        return element;
    }
    const elementsMap: Map<TypographyProps['variant'], keyof ReactHTML> = new Map([
        ['h1', 'h1'],
        ['h2', 'h2'],
        ['h3', 'h3'],
        ['body-lg', 'div'],
        ['body-md', 'div'],
        ['body-sm', 'div'],
        ['display-lg', 'span'],
        ['display-md', 'span'],
        ['label-lg', 'label'],
        ['label-md', 'label'],
        ['label-sm', 'label'],
    ]);

    return elementsMap.get(variant) || 'div';
};
