import { isEmpty } from 'lodash-es';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { selectGroupMembersMicro } from '../../../../common/company/CompanySelectors';
import { createDataId } from '../../../../common/utils/dataId';
import { formatDate } from '../../../../common/utils/formatters';
import { ToggleContent, ToggleContentType } from '../../../../components/ToggleContent/ToggleContent';
import { Typography } from '../../../../components/Ui/Typography';
import { InvoiceDTO } from '../../../../services/types/ApiTypes';
import { getInvoiceHistory } from './InvoiceHistoryActions';
import { historyActionTranslations } from './InvoiceHistoryHelper';
import { selectInvoiceHistory } from './InvoiceHistoryReducers';
import './InvoiceHistory.scss';

export interface Props {
    invoice: InvoiceDTO;
    isNewInvoice?: boolean;
}

export type InvoiceHistoryProps = Props;

const InvoiceHistory: React.FC<InvoiceHistoryProps> = ({ invoice, isNewInvoice }) => {
    const invoiceHistoryLoadable = useSelector(selectInvoiceHistory);
    const groupMembers = useSelector(selectGroupMembersMicro);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    function getGroupMemberFullName(groupMemberId: number) {
        const groupMember = groupMembers?.find((gm) => gm.Id === groupMemberId);
        return formatWithSeparator(groupMember?.Name);
    }

    function formatWithSeparator(text: string) {
        return text ? text + ' | ' : '';
    }

    useEffect(() => {
        if (invoice && !isNewInvoice) {
            dispatch(getInvoiceHistory(invoice.Id));
        }
    }, [invoice, isNewInvoice]);

    return (
        <ToggleContent
            className="history__container"
            type={ToggleContentType.CONTENT_BLOCK}
            toggleContent={
                <Typography variant="h2" element="span">
                    {t('component.history.heading')}
                </Typography>
            }
            defaultOpen={true}
        >
            {!isEmpty(invoice && invoiceHistoryLoadable.payload) && !isNewInvoice ? (
                <ul className="history">
                    {invoiceHistoryLoadable.payload.map((history, i) => (
                        <li key={i} className="history__entry" data-id={createDataId('invoiceHistoryEntry', i)}>
                            <strong className="history__action" data-id={createDataId('invoiceHistoryEntry', i, 'action')}>
                                {t(historyActionTranslations[history.ActionId])} {history.Parameter}
                            </strong>
                            <span className="history__date" data-id={createDataId('invoiceHistoryEntry', i, 'nameAndDate')}>
                                {getGroupMemberFullName(history.GroupMemberId)} {formatDate(history.LogDate, 'dd.MM.yyyy HH:mm:ss')}
                            </span>
                            <span data-id={createDataId('invoiceHistoryEntry', i, 'info')}>{history.AdditionalInfo}</span>
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="no-history-entries">{t('component.history.noHistory')}</p>
            )}
        </ToggleContent>
    );
};

export default InvoiceHistory;
