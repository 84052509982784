import { AxiosError } from 'axios';
import { push } from 'connected-react-router';
import { batch } from 'react-redux';
import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';
import _ from 'lodash-es';

import i18n from '../../../i18n';
import { loadableDataActions } from '../../../common/utils/LoadableData';
import { BackOfficeCompanyDTO, MailboxWorkflowDTO, MailboxDTO, SaveMailboxWorkflowDTO, RossumWorkspaceDTO } from '../../../services/types/BoApiTypes';
import { DispatchThunk } from '../../../storeConfig';
import api from '../../../services/ApiServices';
import { notify } from '../../../common/utils/notify';
import { AddMailboxSubmitFormNormalized, EditMailboxSubmitFormNormalized } from './components/mailboxes/components/AddOrEditMailboxModal/AddOrEditMailboxModalTypes';
import { BOCompanyHistorySearchParams } from './components/boCompanyHistoryList/BOCompanyHistoryListTypes';

const ns = 'back-office/company/details/';

export const boCompanyDetailsLoadableActions = loadableDataActions<string, BackOfficeCompanyDTO>(`${ns}BO_COMPANY_DETAILS_LOADABLE`);
export const boCompanyGrantAccessLoadableActions = loadableDataActions<string, null>(`${ns}BO_COMPANY_GRANT_ACCESS`);
export const boCompanyCloneDatabaseLoadableActions = loadableDataActions<string, BackOfficeCompanyDTO>(`${ns}BO_COMPANY_CLONE`);

export const addOrEditMailboxModalOpen = createAction(`${ns}MAILBOX_ADD_EDIT_MODAL_OPEN`);
export const addOrEditMailboxModalClose = createAction(`${ns}MAILBOX_ADD_EDIT_MODAL_CLOSE`);
export const setEditingMailbox = createAction<MailboxDTO>(`${ns}SET_EDITING_MAILBOX`);
export const clearEditingMailbox = createAction(`${ns}CLEAR_EDITING_MAILBOX`);
export const boCompanyMailboxesLoadableActions = loadableDataActions<string, MailboxDTO[]>(`${ns}GET_COMPANY_MAILBOXES`);
export const addMailboxLoadableActions = loadableDataActions<AddMailboxSubmitFormNormalized, void>(`${ns}MAILBOXES_LOADABLE`);
export const updateMailboxLoadableActions = loadableDataActions<EditMailboxSubmitFormNormalized, MailboxDTO>(`${ns}UPDATE_MAILBOX`);
export const mailboxDetailsLoadableActions = loadableDataActions<MailboxDTO, any>(`${ns}MAILBOX_DETAILS_LOADABLE`);
export const mailboxWorkflowLoadableActions = loadableDataActions<MailboxWorkflowDTO, MailboxWorkflowDTO>(`${ns}MAILBOX_WORKFLOW_LOADABLE`);
export const boCompanyHistoryListLoadableActions = loadableDataActions<string, any>(`${ns}BO_COMPANY_HISTORY_LIST_LOADABLE`);
export const rossumQueuesLoadableActions = loadableDataActions<any, RossumWorkspaceDTO[]>(`${ns}MAILBOX_ROSSUM_QUEUES_LOADABLE`);
export const setEditingMailboxWorkflow = createAction<MailboxWorkflowDTO>(`${ns}SET_EDITING_MAILBOX_WORKFLOW`);
export const clearEditingMailboxWorkflow = createAction(`${ns}CLEAR_EDITING_MAILBOX_WORKFLOW`);
export const saveMailboxWorkflowLoadableActions = loadableDataActions<MailboxWorkflowDTO, any>(`${ns}EDIT_MAILBOX_WORKFLOW_LOADABLE`);
export const updateBoCompanyHistorySearchParams = createAction<BOCompanyHistorySearchParams>(`${ns}UPD_SEARCH_PARAMS`);

export const getBoCompany = (companyGuid: string) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(boCompanyDetailsLoadableActions.request(companyGuid));
            const response = await api.boCompanies.getCompanyByGuid(companyGuid);
            dispatch(boCompanyDetailsLoadableActions.success(response.data));
        } catch (e) {
            console.error(e);
        }
    };
};

export const grantAccess = (companyGuid: string) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(boCompanyGrantAccessLoadableActions.request(companyGuid));
            await api.boCompanies.grantAccess(companyGuid);
            dispatch(boCompanyGrantAccessLoadableActions.success(null));
            notify.success(i18n.t('view.backOffice.companies.actions.grantAccess.grantedSuccessfully'));
        } catch (e) {
            console.error(e);
            dispatch(boCompanyGrantAccessLoadableActions.error(e));
            notify.error(i18n.t('view.backOffice.companies.actions.grantAccess.grantAccessError'));
        }
    };
};

export const cloneDatabase = (companyGuid: string) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(boCompanyCloneDatabaseLoadableActions.request(companyGuid));
            notify.info(i18n.t('view.backOffice.companies.actions.clone.isCloning'), i18n.t('view.backOffice.companies.actions.clone.isCloningTitle'), {
                toastOptions: { autoClose: 4000 },
            });
            const response = await api.boCompanies.cloneDatabase(companyGuid);
            dispatch(boCompanyCloneDatabaseLoadableActions.success(null));
            dispatch(push(`/backoffice/companies/${response?.data?.CompanyGuid}`));
            notify.success(i18n.t('view.backOffice.companies.actions.clone.clonedSuccessfully'));
        } catch (e) {
            console.error(e);
            dispatch(boCompanyCloneDatabaseLoadableActions.error(e));
            dispatch(push(`/backoffice/companies/${companyGuid}`));
            notify.error(i18n.t('view.backOffice.companies.actions.clone.cloneError'));
        }
    };
};

// MAILBOXES
export const boCompanyGetMailboxes = (companyGuid: string) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(boCompanyMailboxesLoadableActions.request(companyGuid));
            const response = await api.boCompanies.getCompanyMailboxes(companyGuid);
            dispatch(boCompanyMailboxesLoadableActions.success(response.data));
        } catch (e) {
            console.error(e);
        }
    };
};

export const boCompanyAddMailbox = (formData: AddMailboxSubmitFormNormalized) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(addMailboxLoadableActions.request(formData));
            const { companyGuid, username, domain } = formData;
            const response = await api.boCompanies.addCompanyMailbox(companyGuid, username, domain);
            const isErrorEmpty = _.isEmpty(response.data.ResponseMessage);

            if (isErrorEmpty) {
                dispatch(addOrEditMailboxModalClose());
                dispatch(addMailboxLoadableActions.success());
                dispatch(push(`/backoffice/companies/${companyGuid}/mailboxes/${response.data.MailboxGuid}`));
                return Promise.resolve();
            } else {
                const errorMessage = response.data.ResponseMessage;
                dispatch(addMailboxLoadableActions.error(new Error(errorMessage)));
                notify.error(i18n.t(errorMessage), null, { toastOptions: { autoClose: 4000 } });
                return Promise.reject(errorMessage);
            }
        } catch (e) {
            console.error(e);
            const err = e as AxiosError;
            dispatch(addMailboxLoadableActions.error(err));
            notify.error(err.message, null, { toastOptions: { autoClose: 4000 } });
            return Promise.reject(err.message);
        }
    };
};

export const boCompanyUpdateMailbox = (mailbox: EditMailboxSubmitFormNormalized, noRefetchList?: boolean) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(updateMailboxLoadableActions.request(mailbox));
            const response = await api.boCompanies.updateCompanyMailbox(mailbox);
            const isErrorEmpty = _.isEmpty(response.data.ResponseMessage);

            if (isErrorEmpty) {
                batch(() => {
                    dispatch(updateMailboxLoadableActions.success(response.data));
                    if (!noRefetchList) {
                        dispatch(boCompanyGetMailboxes(response.data.CompanyGuid));
                    }
                    dispatch(addOrEditMailboxModalClose());
                });
                return Promise.resolve();
            } else {
                const errorMessage = response.data.ResponseMessage;
                dispatch(updateMailboxLoadableActions.error(new Error(errorMessage)));
                notify.error(i18n.t(errorMessage), null, { toastOptions: { autoClose: 4000 } });
                return Promise.reject(errorMessage);
            }
        } catch (e) {
            const err = e as AxiosError;
            dispatch(updateMailboxLoadableActions.error(err));
            notify.error(err.message, null, { toastOptions: { autoClose: 4000 } });
            return Promise.reject(err.message);
        }
    };
};

export const getMailboxDetailsById = (mailboxId: string, fallbackDetails?: MailboxDTO) => async (dispatch: Dispatch) => {
    try {
        dispatch(mailboxDetailsLoadableActions.request(fallbackDetails));
        const response = await api.boCompanies.getCompanyMailboxDetailsById(mailboxId);
        dispatch(mailboxDetailsLoadableActions.success(response.data));
    } catch (e) {
        const err = e as AxiosError;
        dispatch(mailboxDetailsLoadableActions.error(err.response.data));
    }
};

export const getMailboxWorkflow = (mailboxWorkflowGuid: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(mailboxWorkflowLoadableActions.request(null));
        const response = await api.boCompanies.getMailboxWorkflow(mailboxWorkflowGuid);
        dispatch(mailboxWorkflowLoadableActions.success(response.data));
    } catch (e) {
        const err = e as AxiosError;
        dispatch(mailboxWorkflowLoadableActions.error(err?.response?.data));
    }
};

export const saveMailboxWorkflow = (formData: SaveMailboxWorkflowDTO) => async (dispatch: Dispatch) => {
    try {
        dispatch(saveMailboxWorkflowLoadableActions.request(null));
        const response = await api.boCompanies.saveMailboxWorkflow(formData);
        dispatch(saveMailboxWorkflowLoadableActions.success(response.data));
        dispatch(clearEditingMailboxWorkflow());
    } catch (e) {
        const err = e as AxiosError;
        notify.error(err.message, null, { toastOptions: { autoClose: 4000 } });
        dispatch(saveMailboxWorkflowLoadableActions.error(err?.response?.data));
    }
};

export const getBoCompanyHistoryList = (companyGuid: string, searchParams: BOCompanyHistorySearchParams) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(boCompanyHistoryListLoadableActions.request(companyGuid));
            const response = await api.boCompanies.getCompanyHistoryList(companyGuid, searchParams);
            dispatch(boCompanyHistoryListLoadableActions.success(response.data));
        } catch (e) {
            dispatch(boCompanyHistoryListLoadableActions.error(e.response.data));
        }
    };
};

export const getMailboxRossumQueues = () => async (dispatch: DispatchThunk) => {
    try {
        dispatch(rossumQueuesLoadableActions.request(null));
        const response = await api.boCompanies.getRossumQueues();
        dispatch(rossumQueuesLoadableActions.success(response.data));
    } catch (e) {
        dispatch(rossumQueuesLoadableActions.error(e.response.data));
    }
};
