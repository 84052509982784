export const isScientificNotation = (value: number | string) => {
    const regExp = /^-?\d+(\.\d+)?[eE][+-]?\d+$/;
    const val = value.toString();
    return regExp.test(val);
};

export const isNumericValue = (value: number | string): boolean => {
    if (typeof value === 'number') {
        return !isNaN(value);
    }

    if (typeof value === 'string') {
        // Regular expression to match valid numeric strings including scientific notation
        return /^-?\d+(\.\d+)?([eE][+-]?\d+)?$/.test(value);
    }
    return false;
};

export const scientificNotationToBigIntString = (value: number | string) => {
    const [base, exponent] = value.toString().split(/[eE]/);
    const baseWithoutDot = base.replace('.', '');
    const exponentValue = Number(exponent);
    const decimalPlaces = base.split('.')[1]?.length || 0;
    const powerOf10 = BigInt('1' + '0'.repeat(exponentValue - decimalPlaces));

    // Calculate the BigInt value
    const bigIntValue = BigInt(baseWithoutDot) * powerOf10;
    return bigIntValue.toString();
};

export const numberToNumericString = (value: number | string) => {
    if (!isNumericValue(value)) {
        console.log('value is not numeric');
        return value.toString();
    }
    if (isScientificNotation(value)) {
        return scientificNotationToBigIntString(value);
    }

    const numericString = typeof value === 'string' ? value : value.toLocaleString('en-US', { useGrouping: false });
    if (Number.isInteger(Number(numericString))) {
        return BigInt(Number(numericString)).toString();
    }

    return numericString;
};
