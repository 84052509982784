import { TFunction } from 'i18next';
import * as React from 'react';
import FormikField from '../../../common/utils/FormikField';

import { TextInputField, TextInputType } from '../../../components/TextInput/TextInput';

export function EmailTab({ t }: { t: TFunction }) {
    const activateElement = (e: React.FocusEvent<HTMLInputElement>) => {
        if (e.currentTarget.hasAttribute('readonly')) {
            e.currentTarget.attributes.removeNamedItem('readonly');
        }
    };
    return (
        <>
            <FormikField component={TextInputField} type={TextInputType.BORDERED} label={t('views.login.email.label')} readOnly={true} onFocus={activateElement} name="email" dataId="email" />
            <FormikField
                component={TextInputField}
                type={TextInputType.BORDERED}
                label={t('views.login.password.label')}
                readOnly={true}
                onFocus={activateElement}
                name="emailPassword"
                password={true}
                dataId="password"
            />
        </>
    );
}
