'use strict';
import { getIsShowPdfPreviewAutomatically, setIsShowPdfPreviewAutomatically } from "../../src/views/invoice-details/components/invoice-related-documents/InvoiceRelatedDocumentsHelper";

angular.module('dstreamApp.services').factory('interceptorsFactory', ['notifyService', 'localStorageService', '$rootScope', '$timeout', '$window', function(notifyService, localStorageService, $rootScope, $timeout, $window) {

  var authorizationDataString = 'authorizationData';
  var interceptorsFactory = {
    'request': function(config) {

      var expirationTime = new Date(localStorageService.get('ExpirationTime'));
      var isShowPdfPreviewAutomatically = getIsShowPdfPreviewAutomatically();
      var timeLeft = expirationTime.getTime() - new Date().getTime();
      if (timeLeft <= 0) {
        $window.location.href = ('../../login/?expired'); // if the sesstion timed out then show a special message in login
        localStorageService.clearAll();
        localStorageService.set('ExpirationTime', new Date());
        setIsShowPdfPreviewAutomatically(isShowPdfPreviewAutomatically);
        //throw 'Session expired';
      }
      //notifyService.loading('interceptorsFactory.LoadingData', 'interceptorsFactory.PleaseWait');
      return config || $q.when(config);
    },
    'requestError': function(rejection) {
      console.error('Request Error ', rejection);
      notifyService.loadingClear();
      notifyService.error('interceptorsFactory.ErrorWhileSendingData', 'interceptorsFactory.Error', true);
      return null;
    },
    'response': function(data) {
      notifyService.loadingClear();

      if (data.config.url.indexOf('resources/locale-') !== -1) {
        $rootScope.$emit('languageChange');
      }

      // if response has authorization-token set in headers then update it in our localStorage
      var authData = localStorageService.get(authorizationDataString);
      if (data.headers()['authorization-token']) {
        authData.token = data.headers()['authorization-token'];
        localStorageService.set(authorizationDataString, authData);
      }

      // if response has expirationtime set in headers then update it in our localstorage
      if (data.headers()['expirationtime']) {
        var expirationTime = new Date();
        expirationTime.setTime(data.headers()['expirationtime']);
        localStorageService.set('ExpirationTime', expirationTime);
      }


      updateSessionTimer();
      return data;
    },
    'responseError': function(rejection) {
      if (rejection.status === 403) {
          notifyService.error('views.error.403.Toast.Description', 'views.error.403.Toast.Title', false);
          window.location.href = '#/dashboard';
          return null;
      }
      console.error('Response Error ', rejection);

      const methodUrlsErrorExceptions = ['ExportTransactionRowsWithFormulasToXls', 'ExportTransactionRowsToXls'];
      if (rejection.data && (rejection.data.Message || rejection.data.ErrorCode)) {
        return rejection;
      }
      if (rejection.config && methodUrlsErrorExceptions.some(e => rejection.config.url.includes(e))) {
        return rejection;
      }
      notifyService.loadingClear();
      if (rejection.status !== 401 && rejection.xhrStatus !== "abort") { // there is no need to show failed method errors because we immediately redirect to login
        notifyService.error(rejection.data && rejection.data.message ? rejection.data.message : 'interceptorsFactory.ErrorWhileProcessingData', 'interceptorsFactory.Error', true);
      }
      return rejection;
    },
  };


  function updateSessionTimer() {
    $timeout.cancel($rootScope.sessionTimer.session);
    $timeout.cancel($rootScope.sessionTimer.message);

    var timeLeft = ExpirationTime().getTime() - new Date().getTime();
    if (timeLeft > 0) {
    } else {
      console.log(timeLeft);

    }
  }

  function ExpirationTime() {
    return new Date(localStorageService.get('ExpirationTime'));
  }

  var sessWindow = { open: false };

  function timerRun() {
    var timeLeft = ExpirationTime().getTime() - new Date().getTime();

    if (timeLeft <= $rootScope.messageTime && !sessWindow.open) {
      sessWindow = $rootScope.sessionModalInstance.open({
        templateUrl: 'app/components/sessionReminder/ds-session-reminder.html',
        controller: 'DsSessionReminderController',
        windowClass: 'session-modal',
        backdrop: 'static',
      });
      sessWindow.open = true;
      $rootScope.sessionTimer = $timeout(timerRun, 1000);

    }
    else {
      $rootScope.sessionTimer = $timeout(timerRun, 1000);
    }
  }

  $rootScope.sessionTimer = $timeout(timerRun, 1000);


  return interceptorsFactory;
}]);
