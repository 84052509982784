import React, { useState, useEffect } from 'react';

import GooglePlayBadge from '../../../components/Icon/icons/GooglePlayBadge.svg';
import AppleStoreBadge from '../../../components/Icon/icons/AppStoreBadge.svg';

enum Platforms {
    ANDROID = 'android',
    IOS = 'iOS',
    OTHER = 'Other',
}

const AppStoreLinks = () => {
    const [platform, setPlatform] = useState<Platforms>();

    const isIOS = () => {
        return (
            ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
        );
    };

    useEffect(() => {
        if (isIOS()) {
            setPlatform(Platforms.IOS);
        } else if (navigator.userAgent.toLowerCase().indexOf(Platforms.ANDROID) > -1) {
            setPlatform(Platforms.ANDROID);
        } else {
            setPlatform(Platforms.OTHER);
        }
    }, []);

    return (
        <div className="login__apps-links">
            {platform !== Platforms.IOS && (
                <a href="https://play.google.com/store/apps/details?id=com.fitekmobile.prod" target="_blank" rel="noopener noreferrer">
                    <svg className="store-badge">
                        <use xlinkHref={`#${GooglePlayBadge.id}`} />
                    </svg>
                </a>
            )}
            {platform !== Platforms.ANDROID && (
                <a href="https://apps.apple.com/us/app/fitek-in/id1516565209" target="_blank" rel="noopener noreferrer">
                    <svg className="store-badge">
                        <use xlinkHref={`#${AppleStoreBadge.id}`} />
                    </svg>
                </a>
            )}
        </div>
    );
};

export default AppStoreLinks;
