import { Form, Formik } from 'formik';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as Yup from 'yup';

import FormikField from '../../../common/utils/FormikField';
import { Button, ButtonType } from '../../../components/Buttons/Button';
import { ICONS } from '../../../components/Icon/Icon';
import { createDataId } from '../../../common/utils/dataId';
import { TextInputField, TextInputType } from '../../../components/TextInput/TextInput';
import withSuspense from '../../../common/hocs/withSuspense';
import ContentBlockHeading, { ContentBlockHeadingType } from '../../../components/ContentBlock/ContentBlockHeading';
import { ContentBlockHeader } from '../../../components/ContentBlock/ContentBlockHeader';
import { ContentBlockBody } from '../../../components/ContentBlock/ContentBlockBody';
import { ContentBlockFooter } from '../../../components/ContentBlock/ContentBlockFooter';
import { WithTranslateFormErrors } from '../../../components/WithTranslateFormErrors/WithTranslateFormErrors';

import { checkEmailStatus, redirectToLogin, sendPasswordResetLink } from '../loginHelper';

export interface Props {
    showForgotPassword: (state: boolean, shomEmail?: boolean, email?: string) => void;
}

export type ForgotPasswordProps = Props & WithTranslation;

export interface ForgotPasswordFields {
    email: string;
}

const dataId = 'forgotPassword';

export const ForgotPassword = (props: ForgotPasswordProps) => {
    const { showForgotPassword, t } = props;
    const initialFields: ForgotPasswordFields = {
        email: '',
    };
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const resetPasswordValidationSchema: Yup.ObjectSchema<ForgotPasswordFields> = Yup.object<ForgotPasswordFields>().shape({
        email: Yup.string()
            .ensure()
            .required(t('view.backOffice.general.mandatoryField', { fieldName: t('views.login.email.label') }))
            .email(t('global.email.InvalidFormat')),
    });

    const handleSubmit = async (values: ForgotPasswordFields) => {
        const checkStatus = await checkEmailStatus(values.email);
        if (checkStatus) {
            const sendResult = await sendPasswordResetLink(values.email);

            if (sendResult) {
                showForgotPassword(false, true, values.email);
            }
        }
        setIsLoading(false);
    };

    return (
        <Formik
            initialValues={initialFields}
            onSubmit={(values: ForgotPasswordFields) => {
                setIsLoading(true);
                handleSubmit(values);
            }}
            validationSchema={resetPasswordValidationSchema}
            enableReinitialize={true}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(formik) => (
                <WithTranslateFormErrors errors={formik.errors} touched={formik.touched} setFieldTouched={formik.setFieldTouched}>
                    <Form autoComplete="off" className="forgot-password">
                        <ContentBlockHeader className="login__block-header">
                            <Button className="login__block--back-btn" onClick={redirectToLogin} dataId={createDataId(dataId, 'backBtn')} buttonType={ButtonType.ICON_TEXT} icon={ICONS.ARROW_LEFT_24}>
                                {t('views.login.forgotPassword.backToLogin')}
                            </Button>
                            <div className="login__block-header-container">
                                <ContentBlockHeading type={ContentBlockHeadingType.BOLD} dataId={createDataId(dataId, 'headingBlockTitle')}>
                                    {t('views.login.forgotPassword.title')}
                                </ContentBlockHeading>
                            </div>
                        </ContentBlockHeader>
                        <ContentBlockBody className="login__block-body forgot-password" dataId={createDataId(dataId, 'blockBody')}>
                            <FormikField
                                component={TextInputField}
                                wrapperClass="fogot-password__input"
                                type={TextInputType.BORDERED}
                                label={t('views.login.email.label')}
                                name="email"
                                dataId={createDataId(dataId, 'email')}
                            />
                        </ContentBlockBody>
                        <ContentBlockFooter className="login__block-footer" noSeparator={false}>
                            <Button dataId={createDataId(dataId, 'button.continue')} type="submit" loading={isLoading} className="login__button" id="loginButton">
                                {t('views.global.continue')}
                            </Button>
                        </ContentBlockFooter>
                    </Form>
                </WithTranslateFormErrors>
            )}
        </Formik>
    );
};

export default withSuspense(withTranslation()(ForgotPassword));
