import { useEffect, useState } from 'react';

const CAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA3_API_KEY;
const CAPTCHA_ID = 'recaptcha-key';
const CAPTCHA_URL = `https://www.google.com/recaptcha/api.js?render=${CAPTCHA_SITE_KEY}`;

const useReCaptcha3 = (usingRecaptcha: boolean, forceFetchToken: boolean): [string, () => void] => {
    const [script, setScript] = useState<HTMLScriptElement>(null);
    const [token, setToken] = useState<string>(null);

    const onLoad = async () => {
        await window.grecaptcha?.ready(async () => {
            await window.grecaptcha.execute(CAPTCHA_SITE_KEY, { action: 'submit' }).then((tkn: string) => {
                setToken(tkn);
            });
        });
    };

    const loadScriptByURL = (callback?: () => void) => {
        const isScriptThere = document.getElementById(CAPTCHA_ID);

        if (!isScriptThere) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = CAPTCHA_URL;
            script.async = true;
            script.defer = true;
            script.id = CAPTCHA_ID;
            script.onload = function() {
                onLoad();
                if (callback) {
                    callback();
                }
            };

            setScript(document.body.appendChild(script));
        } else {
            onLoad();
        }

        isScriptThere && callback && callback();
    };

    const cleanUp = () => {
        const captchaElement = document.getElementById(CAPTCHA_ID);
        if (script && captchaElement) {
            captchaElement.parentNode.removeChild(captchaElement);
        }
    };

    useEffect(() => {
        // load the script by passing the URL
        if (usingRecaptcha && CAPTCHA_SITE_KEY) {
            loadScriptByURL();
        }
        return cleanUp;
    }, [usingRecaptcha]);

    useEffect(() => {
        forceFetchToken && loadScriptByURL();
        return cleanUp;
    }, [forceFetchToken]);

    const reloadCaptcha = () => {
        loadScriptByURL();
        cleanUp;
    };

    return [token, reloadCaptcha];
};

export default useReCaptcha3;
