import { TFunction } from 'i18next';
import * as React from 'react';
import FormikField from '../../../common/utils/FormikField';

import { TextInputField, TextInputType } from '../../../components/TextInput/TextInput';
import { getCountryMobilePrefix } from '../loginHelper';

export function MobileIdTab({ t, showMobileIdCode, mobileIdVerificationCode, countryCode }: { t: TFunction; showMobileIdCode: boolean; mobileIdVerificationCode: string; countryCode: string }) {
    if (showMobileIdCode) {
        return (
            <div className="login__mobile-id-message">
                <p>
                    <strong>{t('views.login.controlCode.title')}</strong>
                </p>
                <p className="mt-3">{t('views.login.controlCode.description.mobileId')}</p>
                <div className="login__mobile-id-code-wrapper">
                    <span>{t('views.login.controlCode')}</span>
                    <span className="login__mobile-id-code">{mobileIdVerificationCode}</span>
                </div>
            </div>
        );
    }
    return (
        <>
            <FormikField component={TextInputField} type={TextInputType.WITH_ADDON} addonBefore={countryCode} label={t('views.login.idCode.label')} name="idCode" dataId="idCode" />
            <FormikField
                component={TextInputField}
                addonBefore={getCountryMobilePrefix(countryCode)}
                type={TextInputType.WITH_ADDON}
                label={t('views.login.mobile.label')}
                name="mobile"
                dataId="mobile"
            />
        </>
    );
}
