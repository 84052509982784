import axios, { AxiosError } from 'axios';

import { ICONS } from '../../components/Icon/Icon';
import { SelectOptionValue } from '../../components/Select/SelectOption';
import i18nInstance, { languages } from '../../i18n';
import api from '../../services/ApiServices';
import { BaseSearch } from '../../services/types/ApiTypes';
import { EmailStatus, LoginErrorMessages, Session, SSOSessionDTO } from '../../services/controllers/LoginService';
import { notify } from '../../common/utils/notify';
import { createRequest } from '../../common/user/userSettingUtil';
import { LoginErrorResponseMessage, LoginFields, LoginViewTab } from './LoginView';

export const authorizationDataKey = 'ls.authorizationData';
export const expirationTimeKey = 'ls.ExpirationTime';
export const selectedCountryKey = 'selectedCountry';
export const selectedTabKey = 'login.selectedTab';
export const selectedLanguageKey = 'NG_TRANSLATE_LANG_KEY';
export const serbianSSOKey = 'serbianSSO';

export const GEO_IP_URL = 'https://ipgeolocation.abstractapi.com/v1/?api_key=87e457968c8b4de8be7e85688eef6353';

export async function initSession(data: any, login: LoginFields) {
    localStorage.setItem(authorizationDataKey, JSON.stringify({ token: data.Token, userName: login && login.email, isBoAdmin: data.IsBoAdmin }));
    const date = new Date();
    date.setTime(data.ExpirationTime);
    localStorage.setItem(expirationTimeKey, JSON.stringify(date));
    // gets user related companies and sets company info into browser localstorage
    // in login we select first  company as the logIn company
    if (!data?.UserCompanies || data.UserCompanies?.length <= 1) {
        const searchParams: BaseSearch = createRequest('', 'CompanyName', 1, 25);
        const userCompanies = await api.user.getUserCompanies(searchParams);
        data.UserCompanies = userCompanies.data?.Items;
    }
    if (data.UserCompanies?.length) {
        const companyGuid: string = data.UserLastCompanyGuid;
        setCompanyAuthData(data.UserCompanies, companyGuid);
    }
}

export function setCompanyAuthData(companies: any, companyGuid: string) {
    const authData = JSON.parse(localStorage.getItem(authorizationDataKey));
    authData.companyGuid = companyGuid;
    authData.companies = companies;
    localStorage.setItem(authorizationDataKey, JSON.stringify(authData));
}

export function removeAllData() {
    localStorage.removeItem(authorizationDataKey);
    localStorage.removeItem(expirationTimeKey);
}

export const SUPORTED_COUNTRIES_WITH_PERSONAL_CODE: SelectOptionValue<string>[] = [
    {
        text: 'views.global.country.estonia',
        value: 'EE',
        icon: ICONS.EE_SQ,
    },
    {
        text: 'views.global.country.latvia',
        value: 'LV',
        icon: ICONS.LV_SQ,
    },
    {
        text: 'views.global.country.lithuania',
        value: 'LT',
        icon: ICONS.LT_SQ,
    },
    {
        text: 'views.global.country.serbia',
        value: 'RS',
        icon: ICONS.RS_SQ,
    },
];

export const SUPPORTED_COUNTRIES: SelectOptionValue<string>[] = [
    ...SUPORTED_COUNTRIES_WITH_PERSONAL_CODE,
    {
        text: 'views.global.country.australia',
        value: 'AU',
        icon: ICONS.AU_SQ,
    },
    {
        text: 'views.global.country.austria',
        value: 'AT',
        icon: ICONS.AT_SQ,
    },
    {
        text: 'views.global.country.belgium',
        value: 'BE',
        icon: ICONS.BE_SQ,
    },
    {
        text: 'views.global.country.bulgaria',
        value: 'BG',
        icon: ICONS.BG_SQ,
    },
    {
        text: 'views.global.country.cyprus',
        value: 'CY',
        icon: ICONS.CY_SQ,
    },
    {
        text: 'views.global.country.czechRepublic',
        value: 'CZ',
        icon: ICONS.CZ_SQ,
    },
    {
        text: 'views.global.country.finland',
        value: 'FI',
        icon: ICONS.FI_SQ,
    },
    {
        text: 'views.global.country.croatia',
        value: 'HR',
        icon: ICONS.HR_SQ,
    },
    {
        text: 'views.global.country.hungary',
        value: 'HU',
        icon: ICONS.HU_SQ,
    },
    {
        text: 'views.global.country.luxemburg',
        value: 'LU',
        icon: ICONS.LU_SQ,
    },
    {
        text: 'views.global.country.netherlands',
        value: 'NL',
        icon: ICONS.NL_SQ,
    },
    {
        text: 'views.global.country.poland',
        value: 'PL',
        icon: ICONS.PL_SQ,
    },
    {
        text: 'views.global.country.romania',
        value: 'RO',
        icon: ICONS.RO_SQ,
    },
    {
        text: 'views.global.country.slovakia',
        value: 'SK',
        icon: ICONS.SK_SQ,
    },
    {
        text: 'views.global.country.unitedArabEmirates',
        value: 'AE',
        icon: ICONS.AE_SQ,
    },
    {
        text: 'views.global.country.unitedKingdom',
        value: 'GB',
        icon: ICONS.GB_SQ,
    },
].sort((a, b) => (a.text > b.text ? 1 : -1));

export const SUPPORTED_COUNTRIES_EUROPE: SelectOptionValue<string>[] = [
    {
        text: 'views.global.country.albania',
        value: 'AL',
        icon: ICONS.AL_SQ,
    },
    {
        text: 'views.global.country.andorra',
        value: 'AD',
        icon: ICONS.AD_SQ,
    },
    {
        text: 'views.global.country.austria',
        value: 'AT',
        icon: ICONS.AT_SQ,
    },
    {
        text: 'views.global.country.belarus',
        value: 'BY',
        icon: ICONS.BY_SQ,
    },
    {
        text: 'views.global.country.belgium',
        value: 'BE',
        icon: ICONS.BE_SQ,
    },
    {
        text: 'views.global.country.bosniaAndHerzegovina',
        value: 'BA',
        icon: ICONS.BA_SQ,
    },
    {
        text: 'views.global.country.bulgaria',
        value: 'BG',
        icon: ICONS.BG_SQ,
    },
    {
        text: 'views.global.country.cyprus',
        value: 'CY',
        icon: ICONS.CY_SQ,
    },
    {
        text: 'views.global.country.croatia',
        value: 'HR',
        icon: ICONS.HR_SQ,
    },
    {
        text: 'views.global.country.czechRepublic',
        value: 'CZ',
        icon: ICONS.CZ_SQ,
    },
    {
        text: 'views.global.country.denmark',
        value: 'DK',
        icon: ICONS.DK_SQ,
    },
    {
        text: 'views.global.country.estonia',
        value: 'EE',
        icon: ICONS.EE_SQ,
    },
    {
        text: 'views.global.country.finland',
        value: 'FI',
        icon: ICONS.FI_SQ,
    },
    {
        text: 'views.global.country.france',
        value: 'FR',
        icon: ICONS.FR_SQ,
    },
    {
        text: 'views.global.country.germany',
        value: 'DE',
        icon: ICONS.GER_SQ,
    },
    {
        text: 'views.global.country.greece',
        value: 'GR',
        icon: ICONS.GR_SQ,
    },
    {
        text: 'views.global.country.hungary',
        value: 'HU',
        icon: ICONS.HU_SQ,
    },
    {
        text: 'views.global.country.iceland',
        value: 'IS',
        icon: ICONS.IS_SQ,
    },
    {
        text: 'views.global.country.ireland',
        value: 'IE',
        icon: ICONS.IE_SQ,
    },
    {
        text: 'views.global.country.italy',
        value: 'IT',
        icon: ICONS.IT_SQ,
    },
    {
        text: 'views.global.country.latvia',
        value: 'LV',
        icon: ICONS.LV_SQ,
    },
    {
        text: 'views.global.country.liechtenstein',
        value: 'LI',
        icon: ICONS.LI_SQ,
    },
    {
        text: 'views.global.country.lithuania',
        value: 'LT',
        icon: ICONS.LT_SQ,
    },
    {
        text: 'views.global.country.luxemburg',
        value: 'LU',
        icon: ICONS.LU_SQ,
    },
    {
        text: 'views.global.country.malta',
        value: 'MT',
        icon: ICONS.MT_SQ,
    },
    {
        text: 'views.global.country.moldova',
        value: 'MD',
        icon: ICONS.MD_SQ,
    },
    {
        text: 'views.global.country.monaco',
        value: 'MC',
        icon: ICONS.MC_SQ,
    },
    {
        text: 'views.global.country.montenegro',
        value: 'ME',
        icon: ICONS.ME_SQ,
    },
    {
        text: 'views.global.country.netherlands',
        value: 'NL',
        icon: ICONS.NL_SQ,
    },
    {
        text: 'views.global.country.northMacedonia',
        value: 'MK',
        icon: ICONS.MK_SQ,
    },
    {
        text: 'views.global.country.norway',
        value: 'NO',
        icon: ICONS.NO_SQ,
    },
    {
        text: 'views.global.country.poland',
        value: 'PL',
        icon: ICONS.PL_SQ,
    },
    {
        text: 'views.global.country.portugal',
        value: 'PT',
        icon: ICONS.PT_SQ,
    },
    {
        text: 'views.global.country.romania',
        value: 'RO',
        icon: ICONS.RO_SQ,
    },
    {
        text: 'views.global.country.russia',
        value: 'RU',
        icon: ICONS.RU_SQ,
    },
    {
        text: 'views.global.country.sanMarino',
        value: 'SM',
        icon: ICONS.SM_SQ,
    },
    {
        text: 'views.global.country.serbia',
        value: 'RS',
        icon: ICONS.RS_SQ,
    },
    {
        text: 'views.global.country.slovakia',
        value: 'SK',
        icon: ICONS.SK_SQ,
    },
    {
        text: 'views.global.country.slovenia',
        value: 'SI',
        icon: ICONS.SI_SQ,
    },
    {
        text: 'views.global.country.spain',
        value: 'ES',
        icon: ICONS.ES_SQ,
    },
    {
        text: 'views.global.country.sweden',
        value: 'SE',
        icon: ICONS.SE_SQ,
    },
    {
        text: 'views.global.country.switzerland',
        value: 'CH',
        icon: ICONS.CH_SQ,
    },
    {
        text: 'views.global.country.ukraine',
        value: 'UA',
        icon: ICONS.UA_SQ,
    },
    {
        text: 'views.global.country.unitedKingdom',
        value: 'GB',
        icon: ICONS.GB_SQ,
    },
    {
        text: 'views.global.country.vatican',
        value: 'VA',
        icon: ICONS.VA_SQ,
    },
];

export const SUPPORTED_COUNTRIES_MIN: SelectOptionValue<string>[] = SUPPORTED_COUNTRIES.filter((c) => ['EE', 'RS'].includes(c.value));

export const OTHER_COUNTRY: SelectOptionValue<string> = {
    text: 'views.global.country.otherCountry',
    value: 'XX',
    icon: null,
};

export const SUPPORTED_COUNTRIES_ASIA: SelectOptionValue<string>[] = [
    {
        text: 'views.global.country.china',
        value: 'CN',
        icon: ICONS.CN_SQ,
    },
    {
        text: 'views.global.country.japan',
        value: 'JP',
        icon: ICONS.JP_SQ,
    },
    {
        text: 'views.global.country.unitedArabEmirates',
        value: 'AE',
        icon: ICONS.AE_SQ,
    },
];

export const SUPPORTED_COUNTRIES_NORTH_AMERICA: SelectOptionValue<string>[] = [
    {
        text: 'views.global.country.canada',
        value: 'CA',
        icon: ICONS.CA_SQ,
    },
    {
        text: 'views.global.country.usa',
        value: 'US',
        icon: ICONS.US_SQ,
    },
];

export const SUPPORTED_COUNTRIES_OCEANIA: SelectOptionValue<string>[] = [
    {
        text: 'views.global.country.australia',
        value: 'AU',
        icon: ICONS.AU_SQ,
    },
];

export const getSupportedCountriesOfWorld = (): SelectOptionValue<string>[] => {
    const countries = [...SUPPORTED_COUNTRIES_EUROPE, ...SUPPORTED_COUNTRIES_ASIA, ...SUPPORTED_COUNTRIES_NORTH_AMERICA, ...SUPPORTED_COUNTRIES_OCEANIA]
        .map((country) => ({ ...country, text: i18nInstance.t(country.text) }))
        .sort((a, b) => a.text.localeCompare(b.text));
    countries.push({ ...OTHER_COUNTRY, text: i18nInstance.t(OTHER_COUNTRY.text) });
    return countries;
};

export const getSupportedCountriesWithSquareFlags = (): SelectOptionValue<string>[] => {
    return SUPPORTED_COUNTRIES.map((c: SelectOptionValue<string>) => {
        return { ...c, icon: ICONS[`${c.value}_SQ`] };
    });
};

export const getSupportedPersonalCodeCountriesWithSquareFlags = (): SelectOptionValue<string>[] => {
    return SUPORTED_COUNTRIES_WITH_PERSONAL_CODE.map((c: SelectOptionValue<string>) => {
        return { ...c, icon: ICONS[`${c.value}_SQ`] };
    });
};
export const getSupportedCountriesWithSquareFlagsMin = (): SelectOptionValue<string>[] => {
    return SUPPORTED_COUNTRIES_MIN.map((c: SelectOptionValue<string>) => {
        return { ...c, icon: ICONS[`${c.value}_SQ`] };
    });
};

export const SUPPORTED_LANGUAGES: SelectOptionValue<string>[] = languages.map((l) => ({
    text: l.name,
    value: l.locale,
    icon: l.icon,
}));

export const DEFAULT_LANGUAGE = 'en_US';

export const getGeoIpCountry = async () => {
    try {
        const usersResponse = await axios.get(GEO_IP_URL, { timeout: 3000 });
        return Promise.resolve(usersResponse?.data?.country_code);
    } catch (e) {
        console.error(e);
        return Promise.resolve(false);
    }
};

const countryCodeMap = {
    EE: '+372',
    LV: '+371',
    LT: '+370',
};

export function isResetPasswordTokenExpired(error: string) {
    return error === 'PasswordReset.error.TokenExpired';
}

export function getCountryMobilePrefix(countryCode: string) {
    return countryCodeMap[countryCode] || '';
}

export enum CountryDetectionStatus {
    DETECTING = 'DETECTING',
    AVAILABLE = 'AVAILABLE',
    UNAVAILABLE = 'UNAVAILABLE',
}

export function redirectToApp(data: Session | SSOSessionDTO | undefined, redirect?: string) {
    let href;
    if (data && data.IsBetaUser) {
        href = '../../beta/';
    } else if (location.href.indexOf('grantthorton') > -1) {
        href = '../../grantthorton/app/';
    } else {
        href = '../../app/';
    }
    if (redirect) {
        href = redirect && `${href}#${redirect}`;
    }
    window.location.href = href;
}

export function getEmailStatusError(status: EmailStatus) {
    switch (status) {
        case EmailStatus.NotExist:
            return 'views.login.forgotPassword.emailNotFound';
        case EmailStatus.ExistsNotVerified:
            return 'views.login.forgotPassword.emailNotVerified';
        default:
            return '';
    }
}

export function getEmailStatusErrorTitle(status: EmailStatus) {
    switch (status) {
        case EmailStatus.NotExist:
            return 'views.login.incorrectCredentialsTitle';
        case EmailStatus.ExistsNotVerified:
            return 'views.login.forgotPassword.emailNotVerified.title';
        default:
            return 'interceptorsFactory.Error';
    }
}

export const checkEmailStatus = async (value: string) => {
    try {
        const result = await api.login.loginCheckEmailExists(value);
        const emailStatusError = getEmailStatusError(result.data);
        const emailStatusErrorTitle = getEmailStatusErrorTitle(result.data);
        if (emailStatusError) {
            notify.error(i18nInstance.t(emailStatusError), i18nInstance.t(emailStatusErrorTitle));
            return false;
        } else {
            return true;
        }
    } catch (error) {
        console.error(error);
        notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
        return false;
    }
};

export const sendPasswordResetLink = async (value: string) => {
    try {
        const result = await api.login.sendPasswordResetLink(value);
        if (result.data.Success) {
            return true;
        } else {
            notify.error(i18nInstance.t(result.data.Message));
            return false;
        }
    } catch (e) {
        console.error('Request rejected due to:', e);
        return false;
    }
};

export const getUserEmailByResetPasswordToken = async (value: string) => {
    try {
        const result = await api.boUsers.getUserEmailByResetPasswordToken(value);
        if (result.data) {
            return result.data;
        } else {
            notify.error(i18nInstance.t('PasswordReset.error.TokenNotFound'), i18nInstance.t('EmailValidation.error.TokenNotFound'));
            return '';
        }
    } catch (e) {
        console.error('Request rejected due to:', e);
        notify.error(
            i18nInstance.t(e?.response?.data?.message || 'interceptorsFactory.ErrorWhileProcessingData'),
            i18nInstance.t(e?.response?.data?.message ? `view.backOffice.users.tokenCheck.error.title.${e?.response?.data?.message?.split('.').pop()}` : 'interceptorsFactory.Error'),
        );
        return '';
    }
};

export const parseIdCardError = (e: AxiosError<LoginErrorResponseMessage>): string | null => {
    const errorsMap: Map<LoginErrorMessages, string> = new Map([[LoginErrorMessages.TokenIsUsed, 'views.login.tokenIsUsed.message']]);
    if (!e.response?.data?.message?.length) {
        return null;
    }
    const parsedErrorData: LoginErrorResponseMessage = JSON.parse(e.response.data.message);
    return errorsMap.get(parsedErrorData.message) || null;
};

export const redirectToLogin = async () => {
    window.location.href = '/login';
};

const loginTabs: Record<string, LoginViewTab[]> = {
    EE: [LoginViewTab.EMAIL, LoginViewTab.ID_CARD, LoginViewTab.MOBILE_ID, LoginViewTab.SMART_ID, LoginViewTab.USERNAME],
    LT: [LoginViewTab.EMAIL, LoginViewTab.MOBILE_ID, LoginViewTab.SMART_ID, LoginViewTab.USERNAME],
    LV: [LoginViewTab.EMAIL, LoginViewTab.SMART_ID, LoginViewTab.USERNAME],
    RS: [LoginViewTab.EMAIL, LoginViewTab.SERBIA_SSO, LoginViewTab.USERNAME],
    DEFAULT: [LoginViewTab.EMAIL, LoginViewTab.USERNAME],
};

export const getTabs = (country: string): LoginViewTab[] => loginTabs[country] || loginTabs['DEFAULT'];

export const getSelectedTab = (): LoginViewTab => LoginViewTab[localStorage.getItem(selectedTabKey)] || LoginViewTab.EMAIL;
