'use strict';

import { eventTrack } from '../../../src/common/analytics/gtm';

angular
    .module('dstreamApp.components')
    .component('dsSupport', {
        templateUrl: 'app/components/support/ds-support.html',
        controller: DsSupportController,
        bindings: {
            invoice: '<'
        }
    });

/* @ngInject */
function DsSupportController($scope, $timeout, $rootScope, constants, companyDataService, $ngRedux, _) {
    var ctrl = this;
    ctrl.loading = false;
    ctrl.CurrentVersion = constants.CurrentVersion;
    ctrl.$onInit = init;
    ctrl.$onDestroy = destroy;

    ctrl.openUserManual = (userManual) => {

        eventTrack({
            event: 'dashboard_support',
            label: 'Open user manuals',
        });

        if (!userManual.match(/^https?:\/\//i)) {
            userManual = "http://" + userManual;
        }

        window.open(userManual, '_blank');
    }

    ctrl.trackSupportEmailClick = () => {
        eventTrack({
            event: 'dashboard_support',
            label: 'Support email address',
        });
    }

    function setSettings (currentCompanySettings = []) {
        if (currentCompanySettings.length) {
            for (var i = 0; i < currentCompanySettings.length; i++) {
                var setting = currentCompanySettings[i];
                if (setting.Name === 'SupportText')
                    ctrl.supportText = setting.Value;
                if (setting.Name === 'UserManual')
                    ctrl.userManual = setting.Value;
                if (setting.Name === 'SupportEmail')
                    ctrl.supportEmail = setting.Value;
                if (setting.Name === 'SupportPhone')
                    ctrl.supportPhone = setting.Value;
            }
        }
    }

    /* ------------ EVENT LISTENERS --------------*/

    var companySettingsLoadEventListener = $rootScope.$on('ds.currentCompany.companySettingsLoaded', function (event, value) {
        const {company : {currentCompanySettings}} = $ngRedux.getState();
        setSettings(currentCompanySettings);
    });

    /* ------------ ANGULAR FUNCTIONS --------------*/

    function init() {
        const {company : {isCurrentCompanySettingsLoading, currentCompanySettings}} = $ngRedux.getState();

        if (!isCurrentCompanySettingsLoading) {
            setSettings(currentCompanySettings);
        }
    }

    function destroy() {
        companySettingsLoadEventListener();
    }
}