import { Form, Formik } from 'formik';
import { TFunction } from 'i18next';
import * as React from 'react';
import currentLanguage from '../../../common/utils/languageHelper';
import { eventTrack } from '../../../common/analytics/gtm';
import FormikField from '../../../common/utils/FormikField';

import { Button } from '../../../components/Buttons/Button';
import { ContentBlockFooter } from '../../../components/ContentBlock/ContentBlockFooter';
import { ContentBlockHeader } from '../../../components/ContentBlock/ContentBlockHeader';
import ContentBlockHeading from '../../../components/ContentBlock/ContentBlockHeading';
import { SelectField } from '../../../components/Select/Select';
import { SelectOptionValue } from '../../../components/Select/SelectOption';
import { SUPPORTED_COUNTRIES, SUPPORTED_LANGUAGES } from '../loginHelper';

export interface CountryAndLanguageSelectProps {
    children?: React.ReactNode;
    t: TFunction;
    setLanguage: (locale: string) => void;
    setCountry: (countryCode: string) => void;
}

interface CountryAndLanguageSelectValues {
    country: SelectOptionValue<string>;
    language: SelectOptionValue<string>;
}

const CountryAndLanguageSelect: React.FunctionComponent<CountryAndLanguageSelectProps> = (props: CountryAndLanguageSelectProps) => {
    const { t } = props;
    const initialValues: CountryAndLanguageSelectValues = {
        language: SUPPORTED_LANGUAGES.find((l) => l.value === currentLanguage()),
        country: undefined,
    };

    const isContinueDisabled = (values: CountryAndLanguageSelectValues) => {
        return !(values.country && values.language);
    };

    function getSelectOptions(options: SelectOptionValue<string>[]) {
        return options.map((o) => ({
            ...o,
            text: o.text,
        }));
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
                props.setCountry(values.country.value);
                eventTrack({
                    event: 'change_country',
                    label: values.country.value,
                });
            }}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {(formik) => (
                <Form autoComplete="off">
                    <ContentBlockHeader className="login__block-header">
                        <ContentBlockHeading dataId="countryAndLanguageTitleSelect">{t('views.login.notAvailable.title')}</ContentBlockHeading>
                        <p className="content-block__subtitle">{t('views.login.notAvailable.description')}</p>
                        <FormikField
                            component={SelectField}
                            hideIcon={true}
                            isTranslatable
                            label={t('views.global.country')}
                            placeholder={t('views.login.chooseCountry')}
                            name="country"
                            className="login__select"
                            dataId="login.chooseCountry"
                            items={getSelectOptions(SUPPORTED_COUNTRIES)}
                        />

                        <FormikField
                            component={SelectField}
                            label={t('views.global.language')}
                            name="language"
                            className="login__select"
                            dataId="login.chooseLanguage"
                            items={SUPPORTED_LANGUAGES}
                            onChange={(value: SelectOptionValue<string>) => {
                                props.setLanguage(value.value);
                                eventTrack({
                                    event: 'change_language',
                                    label: value.value,
                                });
                            }}
                        />
                    </ContentBlockHeader>
                    {
                        <ContentBlockFooter className="login__block-footer" noSeparator={false}>
                            <Button dataId="login.continue" disabled={isContinueDisabled(formik.values)} type="submit" className="login__button" id="loginButton">
                                {t('views.global.continue')}
                            </Button>
                        </ContentBlockFooter>
                    }
                </Form>
            )}
        </Formik>
    );
};

export default CountryAndLanguageSelect;
